<template>
  <div class="dialog-container">
    <div v-show="!showImprint">
      <h1>Über spende.app</h1>
      <p>Mit dieser Anwendung können Sie teilnehmenden Einrichtungen eine Spende geben. Diese gelangt direkt auf das zugehörige Spendenkonto und wird von dort aus dem Spendenzweck zugewiesen.</p>

      <p>
        Haben Sie technische Probleme oder einen Bug entdeckt?
        <br>
        <a href="mailto:spendeapp@digitalwolff.de">spendeapp@digitalwolff.de</a>
      </p>
      <p v-if="privacyLink">
        Verantwortlich für den Datenschutz ist die teilnehmende Einrichtung:
        <a
          :href="privacyLink"
          target="_blank"
        >
          Datenschutzerklärung
          <v-icon
            x-small
            color="primary">
            {{ mdiArrowTopRight }}
          </v-icon>
        </a>.
      </p>
    </div>
    <div v-show="showImprint">
      <Imprint />
      <br>
      <v-btn @click="showImprint = false">
        zurück
      </v-btn>
    </div>
    <div class="dialog-bottom">
      <span class="app-version">Version {{ appVersion }}</span>
      <a
        class="link-like"
        @click="showAGB"
      >
        AGB
      </a> &ndash;
      <template v-if="!privacyLink">
        <a
          class="link-like"
          @click="showDatenschutz"
        >
          DATENSCHUTZ
        </a> &ndash;
      </template>
      <a
        class="link-like"
        @click="showImpressum"
      >
        IMPRESSUM
      </a>
    </div>
  </div>
</template>
<script>
import Imprint from '@/components/Imprint'
import { mdiArrowTopRight } from '@mdi/js'

export default {
  name: 'AboutDialog',
  components: {
    Imprint
  },
  data () {
    return {
      showImprint: false,
      mdiArrowTopRight
    }
  },
  computed: {
    appVersion: function () {
      return process.env.PACKAGE_VERSION
    },
    privacyLink: function () {
      return ((this.$store.getters['data/organizationSettingsByUrl'] ?? {})[this.$store.getters['display/currentOrganization']?.url] ?? {})?.WS?.privacy_url ?? null
    }
  },
  methods: {
    showAGB: function () {
      if (document.getElementById('app').offsetHeight > 450) {
        this.$router.replace({ name: 'agbs' })
      } else {
        window.open('/agb', '_blank')
      }
    },
    showDatenschutz: function () {
      if (document.getElementById('app').offsetHeight > 450) {
        this.$router.replace({ name: 'data-privacy' })
      } else {
        window.open('/datenschutz', '_blank')
      }
    },
    showImpressum: function () {
      this.showImprint = true
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../styles/main'
.dialog-container
  text-align: left
.dialog-bottom
  text-align: right
.app-version
  font-size: 0.8em
  color: #ccc
  padding-right: 2em
a
  cursor: pointer
p
  font-size: 16px
</style>
