import {
  SET_COLLECTIONS_FOR_URL,
  SET_DATES,
  SET_ORGANIZATIONS,
  SET_PAYMENT_INFORMATION_FOR_URL,
  SET_EXTENSIONS_FOR_PLANS
} from '@/store/mutation-types'

export default {
  /**
    *
    * @param { object } state
    * @param { Array<{id: string, name: string, level: string, parent: string}> } organizations
    */
  [SET_ORGANIZATIONS] (state, { organizations, images }) {
    state.organizations = organizations
    state.images = images
  },
  [SET_DATES] (state, { oldDates, dates }) {
    state.datesById = Object.assign({},
      oldDates,
      state.datesById.reduce((map, obj) => {
        map[obj.id] = obj
        return map
      }, {})
    )
  },
  [SET_COLLECTIONS_FOR_URL] (state, { organizationUrl, collections }) {
    state.collectionsForUrl[organizationUrl] = collections
  },
  [SET_EXTENSIONS_FOR_PLANS] (state, { extensions }) {
    state.extensions = extensions
  },
  [SET_PAYMENT_INFORMATION_FOR_URL] (state, { organizationUrl, paymentInformation }) {
    state.paymentInformationForUrl[organizationUrl] = paymentInformation
  }
}
