<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300.35 55.32"
  >
    <title>spende.app</title>
    <g>
      <path
        class="cls-1"
        d="M21.9,35.52a8.18,8.18,0,0,1-.93,4,8.3,8.3,0,0,1-2.43,2.76,10.3,10.3,0,0,1-3.45,1.59,15.7,15.7,0,0,1-4,.51,16.16,16.16,0,0,1-6-1.11A20.15,20.15,0,0,1,0,40.38l2.64-3.3a14.89,14.89,0,0,0,4.08,2.58,11.59,11.59,0,0,0,4.5.9A7.44,7.44,0,0,0,16,39.27a4.38,4.38,0,0,0,1.56-3.57A3.84,3.84,0,0,0,17.19,34a5.07,5.07,0,0,0-1.26-1.53,12.5,12.5,0,0,0-2.28-1.5q-1.41-.75-3.51-1.65-2.34-1-4.05-2a12.08,12.08,0,0,1-2.79-2,6.83,6.83,0,0,1-1.59-2.43,9.28,9.28,0,0,1-.51-3.27A7.09,7.09,0,0,1,2,16.35,8.1,8.1,0,0,1,4,13.8a9,9,0,0,1,3.09-1.65,13.07,13.07,0,0,1,4-.57,16.57,16.57,0,0,1,5.76.93,14.54,14.54,0,0,1,4.32,2.43l-2.52,3.3A15,15,0,0,0,15,16.11a11.59,11.59,0,0,0-4.26-.69A6.07,6.07,0,0,0,6.87,16.5a3.44,3.44,0,0,0-1.35,2.82,4.16,4.16,0,0,0,.33,1.74A4.22,4.22,0,0,0,7,22.47a11.25,11.25,0,0,0,2.16,1.38q1.35.69,3.33,1.59,2.22,1,4,1.92a14,14,0,0,1,3,2A7.34,7.34,0,0,1,21.27,32,9,9,0,0,1,21.9,35.52Z"
      />
      <path
        class="cls-1"
        d="M55.68,27.48a26.06,26.06,0,0,1-.93,7.35,16.22,16.22,0,0,1-2.54,5.28,10.8,10.8,0,0,1-3.77,3.21,10.15,10.15,0,0,1-4.61,1.08,10.48,10.48,0,0,1-5.08-1.14A13.65,13.65,0,0,1,35,40.2V52.8l-4.56,2.52V12.24H35l-.06,3.84a17.73,17.73,0,0,1,4-3.15,11.48,11.48,0,0,1,9.93-.42,9.62,9.62,0,0,1,3.6,2.85,14.11,14.11,0,0,1,2.4,4.95A26.15,26.15,0,0,1,55.68,27.48Zm-4.44.06q0-6-2.09-9.07a6.72,6.72,0,0,0-5.86-3,8.64,8.64,0,0,0-4.57,1.24A13.94,13.94,0,0,0,35,20V35.8a11.52,11.52,0,0,0,3.5,3.37,9.05,9.05,0,0,0,5,1.39,6.31,6.31,0,0,0,5.86-3.32Q51.24,33.92,51.24,27.54Z"
      />
      <path
        class="cls-1"
        d="M87.9,27.06q0,.84,0,1.38a6.68,6.68,0,0,1-.15,1.08H66.84a14.34,14.34,0,0,0,2.58,8.08,7.37,7.37,0,0,0,6.18,3,12.22,12.22,0,0,0,4.65-.78,23.73,23.73,0,0,0,4-2.22l2.1,3.24q-1.44.89-2.7,1.56a16.29,16.29,0,0,1-2.58,1.13,17.38,17.38,0,0,1-2.76.67,19.82,19.82,0,0,1-3.24.24,12.8,12.8,0,0,1-4.44-.84,10.71,10.71,0,0,1-4.12-2.79,15.16,15.16,0,0,1-3.06-5.13,21.73,21.73,0,0,1-1.22-7.8,21.58,21.58,0,0,1,1-6.78A15.32,15.32,0,0,1,66,16a12.08,12.08,0,0,1,4.1-3.24,11.77,11.77,0,0,1,5.18-1.14,12.37,12.37,0,0,1,5.42,1.14,11.18,11.18,0,0,1,4,3.21,14.33,14.33,0,0,1,2.42,4.92A22.65,22.65,0,0,1,87.9,27.06Zm-4.14-1.2a14.57,14.57,0,0,0-2.33-7.54,7,7,0,0,0-6.16-2.9A7.31,7.31,0,0,0,69.5,18c-1.5,1.71-2.38,4.33-2.66,7.87Z"
      />
      <path
        class="cls-1"
        d="M116.1,43.74V25.28q0-5.12-1.83-7.48a6.56,6.56,0,0,0-5.58-2.38,7.32,7.32,0,0,0-5.53,2.35c-1.5,1.56-2.24,4.06-2.24,7.51V43.74H96.36V12.24h4.56l0,3.84a11,11,0,0,1,3.55-3.24,10.1,10.1,0,0,1,5.17-1.26,13.2,13.2,0,0,1,4.57.75,8.62,8.62,0,0,1,3.49,2.34,10.83,10.83,0,0,1,2.23,4,19.27,19.27,0,0,1,.78,5.85v19.2Z"
      />
      <path
        class="cls-1"
        d="M149.46,43.74V39.9a17.73,17.73,0,0,1-4,3.15,11.48,11.48,0,0,1-9.93.42,9.62,9.62,0,0,1-3.6-2.85,14.11,14.11,0,0,1-2.4-4.95,26.15,26.15,0,0,1-.87-7.17,26.06,26.06,0,0,1,.93-7.35,16.23,16.23,0,0,1,2.55-5.28A10.83,10.83,0,0,1,136,12.66a10.19,10.19,0,0,1,4.62-1.08,10.56,10.56,0,0,1,5.1,1.14,13.69,13.69,0,0,1,3.78,3.06V2.52L154,0V43.74Zm0-23.56A11.56,11.56,0,0,0,146,16.81a9.12,9.12,0,0,0-5-1.39,6.35,6.35,0,0,0-5.88,3.32q-1.92,3.32-1.92,9.7,0,6,2.1,9.08a6.74,6.74,0,0,0,5.88,3,8.73,8.73,0,0,0,4.59-1.24A14,14,0,0,0,149.46,36Z"
      />
      <path
        class="cls-1"
        d="M187.86,27.06q0,.84,0,1.38a6.68,6.68,0,0,1-.15,1.08H166.8a14.34,14.34,0,0,0,2.58,8.08,7.37,7.37,0,0,0,6.18,3,12.22,12.22,0,0,0,4.65-.78,23.73,23.73,0,0,0,4-2.22l2.1,3.24q-1.44.89-2.7,1.56a16.29,16.29,0,0,1-2.58,1.13,17.38,17.38,0,0,1-2.76.67,19.82,19.82,0,0,1-3.24.24,12.8,12.8,0,0,1-4.44-.84,10.82,10.82,0,0,1-4.13-2.79,15.32,15.32,0,0,1-3.06-5.13,22,22,0,0,1-1.21-7.8,21.88,21.88,0,0,1,1-6.78A15.52,15.52,0,0,1,166,16a12,12,0,0,1,4.1-3.24,11.74,11.74,0,0,1,5.18-1.14,12.29,12.29,0,0,1,5.41,1.14,11,11,0,0,1,4,3.21,14.17,14.17,0,0,1,2.43,4.92A22.65,22.65,0,0,1,187.86,27.06Zm-4.14-1.2a14.48,14.48,0,0,0-2.34-7.54,7,7,0,0,0-6.15-2.9A7.3,7.3,0,0,0,169.46,18c-1.5,1.71-2.38,4.33-2.66,7.87Z"
      />
      <path
        class="cls-2"
        d="M200.88,40.56a3.84,3.84,0,0,1-7.68,0,3.89,3.89,0,0,1,1.14-2.76,3.64,3.64,0,0,1,5.4,0A3.89,3.89,0,0,1,200.88,40.56Z"
      />
      <path
        class="cls-2"
        d="M200.88,40.56a3.84,3.84,0,0,1-7.68,0,3.89,3.89,0,0,1,1.14-2.76,3.64,3.64,0,0,1,5.4,0A3.89,3.89,0,0,1,200.88,40.56Z"
      />
      <path
        class="cls-2"
        d="M227.28,43.74V39.9a17.19,17.19,0,0,1-4.05,3.15,11.56,11.56,0,0,1-5.79,1.35,13.36,13.36,0,0,1-3.66-.51,9,9,0,0,1-3.24-1.68,8.22,8.22,0,0,1-2.31-3.09,11.64,11.64,0,0,1-.87-4.8,9.48,9.48,0,0,1,1-4.56A9.18,9.18,0,0,1,211,26.61a11,11,0,0,1,3.85-1.8,17.85,17.85,0,0,1,4.53-.57,18.34,18.34,0,0,1,4.44.51A14.06,14.06,0,0,1,227.28,26v-3.7q0-6.91-6.82-6.92a15.58,15.58,0,0,0-4.57.63,17.79,17.79,0,0,0-4,1.77l-1.68-3.68a20.69,20.69,0,0,1,4.8-1.86,22.59,22.59,0,0,1,5.75-.7c3.4,0,6.1.79,8.08,2.37s3,4.19,3,7.83v22Zm0-14.1a15.43,15.43,0,0,0-3.38-1.32,18.93,18.93,0,0,0-4.69-.48,8.31,8.31,0,0,0-5.41,1.59A5.57,5.57,0,0,0,211.86,34c0,2.36.58,4.07,1.76,5.13a6.64,6.64,0,0,0,4.63,1.59,10,10,0,0,0,5.32-1.38A12.66,12.66,0,0,0,227.28,36Z"
      />
      <path
        class="cls-2"
        d="M266.81,27.48a26.45,26.45,0,0,1-.92,7.35,16.23,16.23,0,0,1-2.55,5.28,10.77,10.77,0,0,1-3.76,3.21A10.18,10.18,0,0,1,255,44.4a10.52,10.52,0,0,1-5.09-1.14,13.8,13.8,0,0,1-3.77-3.06V52.8l-4.56,2.52V12.24h4.56l-.06,3.84a17.73,17.73,0,0,1,4-3.15,11.48,11.48,0,0,1,9.93-.42,9.62,9.62,0,0,1,3.6,2.85,14.11,14.11,0,0,1,2.4,4.95A26.15,26.15,0,0,1,266.81,27.48Zm-4.44.06c0-4-.69-7-2.09-9.07a6.71,6.71,0,0,0-5.86-3,8.66,8.66,0,0,0-4.57,1.24A13.94,13.94,0,0,0,246.11,20V35.8a11.64,11.64,0,0,0,3.5,3.37,9.08,9.08,0,0,0,5,1.39,6.3,6.3,0,0,0,5.86-3.32C261.74,35,262.37,31.8,262.37,27.54Z"
      />
      <path
        class="cls-2"
        d="M300.35,27.48a26.45,26.45,0,0,1-.92,7.35,16.23,16.23,0,0,1-2.55,5.28,10.71,10.71,0,0,1-3.77,3.21,10.11,10.11,0,0,1-4.6,1.08,10.52,10.52,0,0,1-5.09-1.14,13.8,13.8,0,0,1-3.77-3.06V52.8l-4.56,2.52V12.24h4.56l-.06,3.84a17.73,17.73,0,0,1,4-3.15,11.48,11.48,0,0,1,9.93-.42,9.62,9.62,0,0,1,3.6,2.85,14.11,14.11,0,0,1,2.4,4.95A26.15,26.15,0,0,1,300.35,27.48Zm-4.44.06c0-4-.69-7-2.09-9.07a6.71,6.71,0,0,0-5.86-3,8.66,8.66,0,0,0-4.57,1.24A13.94,13.94,0,0,0,279.65,20V35.8a11.64,11.64,0,0,0,3.5,3.37,9.08,9.08,0,0,0,5,1.39A6.31,6.31,0,0,0,294,37.24Q295.9,33.92,295.91,27.54Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'SpendeAppLogo'
}
</script>
<style scoped>
.cls-1 {
  stroke-width: 0;
  stroke: var(--spendeapp-primary-color);
  fill: var(--spendeapp-primary-color);
}
.cls-2 {
  fill: #010101;
}
</style>
