import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import config from '@/config'
import moment from 'moment'
import display from './display'
import payment from '@/jscommon/src/store/payment'
import configuration from '@/jscommon/src/store/configuration'
import data from './data'
import { SET_ORGANIZATION_AND_COLLECTION, UPDATE_TIME, SET_OVERRIDE } from '@/store/mutation-types'

Vue.use(Vuex)

let vuexLocal = null
if (config.features?.saveParish ?? false) {
  vuexLocal = new VuexPersistence({
    key: 'spende.app-state',
    storage: window.localStorage,
    reducer: (state) => (config.features.saveParish ? { 'display.parishId': state.display.organizationId } : {}), // only save selected parishId  FIXME
    filter: (mutation) => config.features.saveParish && mutation.type === SET_ORGANIZATION_AND_COLLECTION
  })
}

export default new Vuex.Store({
  state () {
    return {
      now: moment(),
      label: 'spende.app',
      overrideSettings: {}
    }
  },
  modules: {
    display,
    payment,
    data,
    configuration
  },
  plugins: (vuexLocal !== null)
    ? [
        vuexLocal.plugin
      ]
    : [],
  mutations: {
    [UPDATE_TIME] (state) { state.now = moment() },
    [SET_OVERRIDE] (state, data) { state.overrideSettings = { ...state.overrideSettings, ...data } }
  },
  actions: {
    startTimer ({ commit }) {
      function doUpdate () {
        commit(UPDATE_TIME)
        // Execute this again at ~1 second past the next full minute
        setTimeout(doUpdate, 60000 - (moment() % 60000) + 1000)
      }
      doUpdate()
    }
  }
})
