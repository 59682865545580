const STATE = {
  organizations: [],
  extensions: [],
  images: [],
  datesById: {},
  collectionsForUrl: {},
  paymentInformationForUrl: {}
}

export default STATE
