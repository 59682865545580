<template>
  <v-app
    id="app"
    ref="vue-root"
  >
    <header>
      <div
        id="inner_header"
        :class="headerStyle()"
      >
        <!-- v-if="settings && settings['spende.app']" -->
        <SpendeAppLogo class="label_logo" />
      </div>
    </header>
    <main>
      <router-view />
    </main>
  </v-app>
</template>
<script>

import { updateVh } from '@/jscommon/src/lib/vh'
import SpendeAppLogo from '@/components/SpendeAppLogo'
import { SET_OVERRIDE } from '@/store/mutation-types'
import { mapGetters } from 'vuex'

export default {
  components: { SpendeAppLogo },
  metaInfo: {
    title: "spende.app - Online Spenden",
    titleTemplate: "%s | spende.app",
  },
  computed: {
    ...mapGetters('data', [
      'organizationSettingsByUrl',
      'imageBackgroundUrlByHash'
    ]),
    ...mapGetters('display', [
      'currentOrganization'
    ]),
    settings () {
      return (this.organizationSettingsByUrl[this.currentOrganization?.url] ?? {}) ?? {}
    }
  },
  created () {
    updateVh()

    // We listen to the resize event
    window.addEventListener('resize', () => {
      updateVh()
    })

    this.$store.dispatch('startTimer')
  },
  mounted () {
    if (this.$route.query.remoteControl !== undefined) {
      window.addEventListener('message', this.receiveMessage)
    }
    updateVh()
    window.setTimeout(updateVh, 1000) // Sanity fallback, recompute after 1s, for the iframe case
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    headerStyle: function () {
      switch (this.$route.name) {
        case 'organization-selected': return 'organization-selected'
        case 'home': return 'home'
        default: return ''
      }
    },
    receiveMessage: function (event) {
      // FIXME Check sender?
      const override = {}

      if (event?.data?.setPrimaryColor ?? null) {
        override.primaryColor = event.data.setPrimaryColor
      }

      if (event?.data?.setBackground ?? null) {
        // FIXME Check URL prefix
        override.backgroundImageUrl = `${event.data.setBackground}`
      }

      if (Object.keys(override).length > 0) {
        this.$store.commit(SET_OVERRIDE, override)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
#app
  --spendeapp-primary-color: #03867b
  position: fixed
  height: 100vh
  width: 100vw
  overflow: hidden

  @media only screen and (max-width: 65em),
    only screen and (max-height: 39em)
    background: #E6E6E6

  ::selection
    background: var(--spendeapp-primary-color)
  ::-moz-selection
    background: var(--spendeapp-primary-color)

header
  position:absolute
  width: 100%
  margin-top: 0em
  padding: 5px

  @media only screen and (max-width: 300px),
    only screen and (max-height: 25em)
    width: calc(100% - 5em)

  #inner_header
    max-width: 50em
    margin-left: auto
    margin-right: auto
  .label_logo
    margin-top: 5px
    margin-left: 5px
    max-width: 150px
    width: 45vw

.home
  margin-top: 2em

  @media only screen and (max-width: 65em),
    only screen and (max-height: 39em)
    margin-top: 0em

.organization-selected
  margin-top: 2em

  @media only screen and (max-width: 650px),
    only screen and (max-height: 37em)
    margin-top: 0em

body
  display: flex
  flex-direction: column
footer
  text-align: center
  vertical-align: bottom
  position: absolute
  bottom: 0
  left: 0
  right: 0

  .nobreak
    white-space: nowrap

  @media (max-height: 76em)
    background-color: rgba(255, 255, 255, 0.7)

header
  h1
    font-size: 28px;
    margin: 0
    line-height: 1
  h3
    padding-top: 7px;
    font-size: 18px;
    margin: 0
    line-height: 1
</style>
