import axios from 'axios'
import config from '@/config'
import {
  SET_COLLECTIONS_FOR_URL,
  SET_DATES,
  SET_ORGANIZATIONS,
  SET_PAYMENT_INFORMATION_FOR_URL,
  SET_EXTENSIONS_FOR_PLANS
} from '@/store/mutation-types'
import { SET_DONATION_RECEIPT_CONFIGURATION } from '../../jscommon/src/store/payment/mutation-types'

/**
*
* @param { function } commit
* @param { State } rootState
*/
export async function fetchOrganizations ({ commit, rootState }) {
  try {
    const response = await axios.get(config.backend.rest + 'app/organization/with_meta/?app=spende.app')
    const { organizations, images } = response.data

    await commit(SET_ORGANIZATIONS, { organizations, images })
  } catch (error) {
    // FIXME Implement retry with backoff. And/or error message
  }
}

export async function fetchCollectionInformationForUrl ({ commit, rootState }, { organizationUrl }) {
  try {
    let collections = null
    let calendar = null
    let paymentInformation = null
    let extensions = null
    const dates = {}
    const response = await axios.get(`${organizationUrl}collection_information/?app=spende.app`)
    collections = []
    calendar = []
    for (const date of response.data.dates) {
      calendar[date.id] = date
    }
    for (const collection of response.data.collections) {
      if (collection.plan?.date !== null && collection.plan?.date in calendar) {
        collection.plan.date = calendar[collection.plan.date]
      }
      collections.push(collection)
    }
    paymentInformation = response.data.payment_information
    extensions = response.data.extensions

    const donationReceiptConfiguration = response.data?.donation_receipt_configuration

    await Promise.allSettled([
      await commit(SET_COLLECTIONS_FOR_URL, { organizationUrl, collections }),
      await commit(SET_EXTENSIONS_FOR_PLANS, { extensions }),
      await commit(SET_PAYMENT_INFORMATION_FOR_URL, { organizationUrl, paymentInformation }),
      await commit('payment/' + SET_DONATION_RECEIPT_CONFIGURATION, { donationReceiptConfiguration }, { root: true }),
      await commit(SET_DATES, { oldDates: rootState['data/datesById'], dates })
    ])
  } catch (error) {
    // FIXME retry etc.
  }
}
