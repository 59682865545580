<template>
  <div class="hand-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.84mm"
      height="5.35mm"
      viewBox="0 0 33.57 15.18"
    >
      <g>
        <polyline
          class="cls-1"
          points="0 12.69 3.22 12.69 3.22 3.21 0 3.21"
        />
        <path
          class="cls-1"
          d="M3.22,3.21A9.34,9.34,0,0,1,9.35.5c3.58,0,11.11,4.15,11.11,4.15,2.49,2.11.35,4.28-1.63,3.8-.58-.26-8.07-3.19-8.07-3.19"
        />
        <path
          class="cls-1"
          d="M5.68,10.68s5.23,4.21,8.75,4S31.88,6.88,32.84,6.15,32,2.26,29.37,1.75c-2.3-.45-8.13,3-8.37,3"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'HandOutline'
}
</script>
<style lang="stylus" scoped>
.hand-icon
  display: inline-block

.cls-1
  fill: none
  stroke: var(--spendeapp-primary-color)
  stroke-miterlimit: 10
</style>
