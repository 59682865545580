export function setColor (hexColor, kind = 'primary', scope = null) {
  let global = false
  if (scope === null) {
    scope = document.getElementById('app')
    global = true
  }
  scope.style.setProperty(`--spendeapp-${kind}-color`, hexColor)
  if (global) {
    const favicon = document.querySelector("link[rel*='icon']")
    if (favicon) {
      const data = '<svg xmlns="http://www.w3.org/2000/svg" width="20mm" height="20mm" viewBox="0 0 56.69 56.69">\n' +
        '  <defs>\n' +
        '    <style>\n' +
        '      .cls-1 {\n' +
        `        fill: ${hexColor};\n` +
        '      }\n' +
        '\n' +
        '      .cls-2 {\n' +
        '        fill: #fff;\n' +
        '      }\n' +
        '    </style>\n' +
        '  </defs>\n' +
        '  <g>\n' +
        '    <rect class="cls-1" width="56.69" height="56.69"/>\n' +
        '    <g>\n' +
        '      <path class="cls-2" d="M13.92,26.36A11.45,11.45,0,0,1,21.46,23c4.4,0,12.8,5.79,12.8,5.79,2.73,1.84.5,3.77-2.19,2.63L23.54,28,22.6,29.6l6.84,2.93c1.76.72,3.8,1.46,5.64,1a3.19,3.19,0,0,0,2.58-3.39,2.74,2.74,0,0,0-.18-.85,2.59,2.59,0,0,0-.71-1.06l.53-.38c.63-.54,6.56-3.21,9.11-2.67,3.23.69,5,4,4,4.82S32,39.47,27.7,39.75,13.92,34.3,13.92,34.3V26"/>\n' +
        '      <rect class="cls-2" x="7.33" y="25.26" width="4.78" height="11.66"/>\n' +
        '    </g>\n' +
        '  </g>\n' +
        '</svg>\n'
      favicon.href = 'data:image/svg+xml;base64,' + btoa(data)
    }
  }
}
