<template>
  <div
    id="home"
    :style="{ backgroundImage: 'url(' + require('@/assets/ui/bg.svg') + ')', backgroundRepeat: 'repeat' }"
  >
    <div id="content-container">
      <div class="logo-layer">
        <div class="top-row">
          <div class="top-left" />
          <div class="top-right">
            <HandSquare
              id="home-logo"
              size="4.5em"
            />
          </div>
        </div>
        <div class="bottom-row">
          <div class="bottom-left" />
          <div class="bottom-right" />
        </div>
      </div>
      <div id="mobile-features">
        <img
          src="@/assets/ui/phone_mockup.png"
          srcset="@/assets/ui/phone_mockup.webp 1x"
          style="width: 27em; position: absolute; padding-left: 2em;"
        >
        <div id="mobile-features-text">
          <br>
          Eigener Hintergrund<br>
          <div class="horizontal-line" />
          <br>
          Eigenes Logo<br>
          <div class="horizontal-line" />
          <br>
          <br>
          Betragsauswahl<br>
          <div class="horizontal-line" />
          <br>
          <br>
          <br>
          Mehrere Spendenzwecke<br>
          <div class="horizontal-line" />
          <br>
          <br>
          <br>
          <br>
          <br>
          Individuelle Farbe
          <div class="horizontal-line" />
        </div>
      </div>
      <div
        class="home-container"
        :class="{'show-more': showMoreOrganizations}"
      >
        <div id="button-layer">
          <div
            id="button-layer-inner"
            @click="showAboutDialog = true"
          >
            <QuestionMark id="question-mark" />
          </div>
        </div>
        <div
          class="start-container"
          :class="{'show-more': showMoreOrganizations}"
        >
          <v-text-field
            v-model="organizationFilter"
            class="mt-8 mb-0"
            placeholder="Ort oder Organisation suchen"
            outlined
            filled
            :prepend-inner-icon="mdiMagnify"
            hide-details="auto"
            background-color="#ffffff"
          />
          <br>
          <div class="start-list-box">
            <OrganizationTile
              v-for="organization in resultItems"
              :key="organization.id"
              :organization="organization"
            />
          </div>
          <div
            v-show="filteredItems.length===0"
            id="no-result-label"
          >
            <br>
            Kein Suchergebnis
          </div>
        </div>
      </div>
      <div class="more-list-box">
        <OrganizationTile
          v-for="organization in resultItemsMore"
          :key="organization.id"
          :organization="organization"
        />
      </div>
    </div>
    <v-dialog
      ref="aboutDialog"
      v-model="showAboutDialog"
      max-width="600px"
    >
      <v-card class="dialog-content pa-6">
        <v-row justify="end">
          <v-col cols="1">
            <v-btn
              fab
              small
              @click="showAboutDialog = false"
            >
              <v-icon small>
                {{ mdiClose }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <AboutDialog />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HandSquare from '@/components/HandSquare'
import QuestionMark from '@/components/QuestionMark'
import AboutDialog from '@/components/AboutDialog'
import OrganizationTile from '@/components/OrganizationTile'
import { SET_ORGANIZATION_ID } from '@/store/action-types'
import { mapGetters } from 'vuex'
import { mdiMagnify, mdiClose } from '@mdi/js'

export default {
  name: 'Home',
  components: {
    HandSquare,
    QuestionMark,
    AboutDialog,
    OrganizationTile
  },
  data () {
    return {
      organizationFilter: '',
      showMoreOrganizations: false,
      shuffledOrgas: [],
      mdiMagnify,
      mdiClose,
      showAboutDialog: false
    }
  },
  computed: {
    ...mapGetters('data', {
      organizations: 'relevantOrganizations',
      organizationSettingsByUrl: 'organizationSettingsByUrl',
      imageLookupByHash: 'imageLookupByHash',
      imageThumbnailUrlByHash: 'imageThumbnailUrlByHash'
    }),
    filteredItems () {
      return this.shuffledOrgas.filter(organization => {
        return this.$options.filters.shortenParish(organization.name).toLowerCase().includes(this.organizationFilter.toLowerCase()) ||
        organization.name.toLowerCase().includes(this.organizationFilter.toLowerCase())
      })
    },
    filteredItemsWithSignup () {
      const retval = []
      this.filteredItems.forEach((obj, idx) => {
        retval.push({
          ...obj,
          signUpDummy: false
        })
        if (idx % 15 === 1) {
          retval.push({
            id: `dummy${idx}`,
            signUpDummy: true
          })
        }
      })
      return retval
    },
    resultItems () {
      return this.filteredItemsWithSignup.slice(0, 6)
    },
    resultItemsMore () {
      return this.filteredItemsWithSignup.slice(6)
    }
  },
  async mounted () {
    await this._intInitialActions()
  },
  methods: {
    closeAboutDialog: function () {
      this.showAboutDialog = false
      // this.$refs.aboutDialog.close()
    },
    getStyleForOrganizationThumb (organization) {
      const primaryColor = ((this.organizationSettingsByUrl[organization.url] ?? {})?.WS ?? {})?.primary_color ?? 'inherit'
      const backgroundImage = this.imageThumbnailUrlByHash[this.organizationSettingsByUrl[organization.url]?.assets?.WS?.background?.image ?? null] ?? null
      return `--spendeapp-primary-color: ${primaryColor}; background-image: url('${backgroundImage}')`
    },
    getCustomLogoForOrganization (organization) {
      let hash = this.organizationSettingsByUrl[organization.url]?.assets?.WS?.logo?.image ?? null
      hash = this.organizationSettingsByUrl[organization.url]?.assets?.WS?.main?.image ?? null
      if (!hash) {
        return null
      }
      return this.imageThumbnailUrlByHash[hash] ?? null
    },
    _intInitialActions: async function () {
      if (this.$route.params) {
        if (this.$store.state.display.organizationId !== this.$route.params.organizationId) {
          await this.$store.dispatch('display/' + SET_ORGANIZATION_ID, this.$route.params.organizationId)
        }
      }

      if (!this.$store.state.data.organizations.length) {
        await this.$store.dispatch('data/fetchOrganizations')
      }
      await this.$store.dispatch('configuration/fetchStaticConfiguration')
      this.shuffledOrgas = this.organizations
      for (let i = this.shuffledOrgas.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.shuffledOrgas[i], this.shuffledOrgas[j]] = [this.shuffledOrgas[j], this.shuffledOrgas[i]]
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#home
  width: 100vw
  height: 100vh /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) /* Set in App.vue */
  justify-content: center
  display: flex
  flex-direction: row
  overflow: scroll
  font-size: 14px

  @media only screen and (max-width: 65em),
    only screen and (max-height: 39em)
    background: white
    margin-top: 0em

  .logo-layer
    width: 100vw
    max-width: 100%
    max-height: 50em
    position: relative
    margin-top: 2em
    margin-bottom: 1em
    display: flex
    flex-direction: column
    justify-content: space-between
    pointer-events: none

    .top-row, .bottom-row
      display: flex
      flex-direction: row
      justify-content: space-between

    #home-logo
      float: right
      margin-right: 9em

      @media only screen and (max-width: 65em),
        only screen and (max-height: 39em)
        display: none

  #question-mark
    width: 2.25em
    height: 2.25em
    float: right
    padding: 1em
    cursor: pointer

  #content-container
    width: 64em
    margin-left: auto
    margin-right: auto
    z-index: 2

    @media only screen and (max-width: 65em),
      only screen and (max-height: 39em)
      width: 100%
      z-index: 1

  .home-container
    width: 28.5em
    height: 37.5em
    margin: 18px 36px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0
    margin-top: 0
    float: right
    background: white
    overflow-y: hidden
    overflow-x: hidden
    border-bottom: 0
    padding: 0em

    @media only screen and (max-width: 65em),
      only screen and (max-height: 39em)
      margin-top: 3em
      margin-bottom: 0em
      width: 100%
      margin-left: auto
      height: auto
      max-height: none
      background: none

  .home-container.show-more
    height: auto

  .start-container
    text-align: left
    color: rgba(0,0,0,0.54)
    padding: 3em
    padding-bottom: 0em
    height: auto

    @media only screen and (max-width: 65em),
      only screen and (max-height: 39em)
      padding-bottom: 0em

  #mobile-features
    margin-left: auto
    width: 34.5em
    height: 37.5em
    padding-right: 1em
    float: left
    position: relative
    background: white

    @media only screen and (max-width: 65em),
      only screen and (max-height: 39em)
      display: none

    img
      width: 20em
      float: left

    #mobile-features-text
      float: right
      text-align: right
      font-weight: bold
      line-height: 1.6em
      width: 13em

  .start-list-box
    position: relative
    display: block
    text-align: center
    display: grid
    grid-auto-rows: 10em
    grid-template-columns: 12em 12em
    margin-left: auto
    margin-right: auto
    width: 100%

    @media only screen and (max-width: 65em),
      only screen and (max-height: 39em)
      grid-auto-rows: 12em
      grid-template-columns: repeat(auto-fill, 18em)
      justify-content: center;

  .more-list-box
    position: relative
    display: block
    text-align: center
    display: grid
    grid-auto-rows: 10em
    grid-template-columns: repeat(auto-fill, 12em)
    margin-left: auto
    margin-right: auto
    background: rgba(150,150,150,0)
    justify-content: flex-end
    padding-right: 1.6em
    padding-top: 2em
    background: white

    @media only screen and (max-width: 65em),
      only screen and (max-height: 39em)
      grid-auto-rows: 12em
      grid-template-columns: repeat(auto-fill, 18em)
      justify-content: center;
      width: calc(100% - 6em)
      padding-right: 0em
      padding-top: 0em
      background: none

  #about-button
    margin-right: 1em
    margin-top: 1em
    width: 2em
    height: 2em
    cursor: pointer
    pointer-events: auto
    text-align: center
    float: right

    span
      font-size: 2.5em

  .help-circle-outline
    color: rgba(0,0,0,0.54)
    font-size: 2.5em
    width: 2em
    margin-left: -1em
    padding: 0.1em
    line-height: 0.3em
    text-align: center

  #questionmark
    position: absolute
    background: red
    width: 1em
    height: 1em

  #about-icon
    border-radius: 100%
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5)

  #organization-search-input-box
    margin-top: 1em
    padding: 1em
    width: 100%
    //width: calc(100% - 2em - 2px)
    height: 3.25em
    background: #DAD9D7
    border: 1px solid black

  #organization-selection
    overflow: hidden

  #organization-search-input
    position: relative
    width: 80%
    border: 0
    background: #DAD9D7
    font-size: small
    float: left

  textarea:focus, #organization-search-input:focus
    outline: none

  #organization-search-icon
    float: left
    bottom: 0.5em

  .horizontal-line
    background: var(--spendeapp-primary-color)
    height: 2px

  #more-label
    position: absolute
    cursor: pointer
    bottom: 0em
    right: 1em
    padding: 0em
    margin: 0
    color: var(--spendeapp-primary-color)
    text-align: right

  #no-result-label
    width: 100%
    text-align: center

  h3
    color: $clr-secondary-text-

  a
    color: var(--spendeapp-primary-color)

</style>
