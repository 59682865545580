<template>
  <div
    class="hand-square"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :style="{width: size, height: size}"
      viewBox="0 0 56.69 56.69"
    >
      <g>
        <rect
          class="cls-1"
          width="56.69"
          height="56.69"
        />
        <g>
          <path
            class="cls-2"
            d="M13.92,26.36A11.45,11.45,0,0,1,21.46,23c4.4,0,12.8,5.79,12.8,5.79,2.73,1.84.5,3.77-2.19,2.63L23.54,28,22.6,29.6l6.84,2.93c1.76.72,3.8,1.46,5.64,1a3.19,3.19,0,0,0,2.58-3.39,2.74,2.74,0,0,0-.18-.85,2.59,2.59,0,0,0-.71-1.06l.53-.38c.63-.54,6.56-3.21,9.11-2.67,3.23.69,5,4,4,4.82S32,39.47,27.7,39.75,13.92,34.3,13.92,34.3V26"
          />
          <rect
            class="cls-2"
            x="7.33"
            y="25.26"
            width="4.78"
            height="11.66"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'HandOutline',
  props: {
    size: {
      default: '2em',
      type: String
    }
  }
}
</script>
<style lang="stylus" scoped>
.hand-square
  display: inline-block

.cls-1
  fill: var(--spendeapp-primary-color)

.cls-2
  fill: white
  stroke-miterlimit: 10
</style>
