import { merge as deepMerge } from 'lodash'
const DEFAULT_BACKGROUND_IMAGE = 'Holzschale_Kollektenkorb.jpg'

/**
*
* @param { {organizations: Array<{id: string, name: string, url: string, level: string, parent: string, settings_inherit: Any, settings_local: Any}>} } state
* @param getters
* @returns Array<{id: string, name: string, level: string, parent: string, settings_inherit: Any, settings_local: Any}>
*/
export function relevantOrganizations (state, getters) {
  if (!state.organizations) {
    return []
  }
  const orgSettingsByUrl = getters.organizationSettingsByUrl
  return state.organizations.filter(value => {
    return (orgSettingsByUrl[value.url] ?? {})?.override?.enableWS ?? (['parish', 'other'].includes(value.level))
  })
}

export function imageLookupByHash (state) {
  return state.images.reduce((map, obj) => {
    map[obj.hash] = obj
    return map
  }, {})
}

export function imageThumbnailUrlByHash (state) {
  return state.images.reduce((map, obj) => {
    // Select the smallest variant
    const variants = [...obj.variants]
    variants.sort((a, b) => {
      return a.width - b.width
    })
    map[obj.hash] = variants[0].url
    return map
  }, {})
}

export function imageBackgroundUrlByHash (state) {
  return state.images.reduce((map, obj) => {
    // Select largest variant that is smaller than 2048 width, smallest otherwise
    const variants = [...obj.variants]
    variants.sort((a, b) => {
      // Largest first
      return b.width - a.width
    })
    const variantsFiltered = variants.filter((obj) => {
      return obj.width < 2048
    })
    if (variantsFiltered.length > 0) {
      map[obj.hash] = variantsFiltered[0].url
    } else {
      map[obj.hash] = variants[variants.length - 1].url
    }

    if (map[obj.hash].includes(DEFAULT_BACKGROUND_IMAGE)) {
      map.default = map[obj.hash]
    }

    return map
  }, {})
}

/**
 *
 * @param { {organizations: Array<{id: string, url: string, name: string, level: string, parent: string}>} } state
 * @param getters
 * @returns [key: string]: {id: string, url: string, name: string, level: string, parent: string}
 */
export function organizationLookupByUrl (state, getters) {
  return state.organizations.reduce((map, obj) => {
    map[obj.url] = obj
    return map
  }, {})
}

/**
 *
 * @param { {organizations: Array<{id: string, name: string, level: string, parent: string, settings_inherit: Any, settings_local: Any}>} } state
 * @param getters
 * @returns [key: string]: {id: string, name: string, level: string, parent: string, settings_inherit: Any, settings_local: Any}
 */
export function organizationLookupById (state, getters) {
  return state.organizations.reduce((map, obj) => {
    map[obj.id] = obj
    return map
  }, {})
}

/**
 *
 * @param { {organizations: Array<{id: string, name: string, level: string, parent: string, settings_inherit: Any, settings_local: Any}>} } state
 * @param getters
 * @returns Array<{id: string, name: string, level: string, parent: string, settings_inherit: Any, settings_local: Any}>
 */
export function relevantOrganizationListOrdered (state, getters) {
  if (!state.organizations) {
    return []
  }
  const result = [...getters.relevantOrganizations]
  result.sort((a, b) => a.name.localeCompare(b.name))
  return result
}

export function organizationSettingsByUrl (state, getters) {
  if (!state.organizations) {
    return {}
  }
  const orgsByUrl = getters.organizationLookupByUrl
  const parentCache = {}
  const defaultSettings = {
    branding: '_default'
  }
  function fillData (orgUrl) {
    if (Object.prototype.hasOwnProperty.call(parentCache, orgUrl)) {
      // Nothing
    } else {
      if (orgsByUrl[orgUrl].parent) {
        fillData(orgsByUrl[orgUrl].parent)
      }
      parentCache[orgUrl] = deepMerge(
        {},
        defaultSettings,
        parentCache[orgsByUrl[orgUrl].parent] ?? {},
        (orgsByUrl[orgUrl]?.settings_inherit ?? {})
      )
    }
  }

  state.organizations.forEach((org) => fillData(org.url))
  state.organizations.forEach((org) => {
    parentCache[org.url] = deepMerge(
      {},
      parentCache[org.url],
      (orgsByUrl[org.url]?.settings_local ?? {})
    )
  })

  return parentCache
}

export function shortPathTree (state, getters) {
  if (!state.organizations) {
    return {}
  }
  const settingsByUrl = getters.organizationSettingsByUrl
  const orgsByUrl = getters.organizationLookupByUrl

  const pathTemp = {
    null: { parent: null, children: {}, shortName: null }
  }

  function findPathToRoot (orgUrl) {
    if (!orgUrl) {
      return null
    }
    const org = orgsByUrl[orgUrl]
    if (Object.prototype.hasOwnProperty.call(pathTemp, orgUrl)) {
      return orgUrl
    }
    const shortName = settingsByUrl[org.url]?.short_path ?? null
    if (shortName) {
      const parent = findPathToRoot(org.parent)
      pathTemp[orgUrl] = {
        shortName,
        children: {},
        parent,
        url: orgUrl
      }
      pathTemp[parent].children[shortName] = orgUrl
      return orgUrl
    } else {
      return findPathToRoot(org.parent)
    }
  }

  state.organizations.forEach((org) => {
    findPathToRoot(org.url)
  })

  return pathTemp
}
