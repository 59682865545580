export default {
  methods: {
    formatAmount (amount, options = {}) {
      switch (this.currentOrganization?.currency ?? "EUR") {
        case "CHF":
          return this.$options.filters.currency_CHF(amount, options)
        case "EUR":
          return this.$options.filters.currency(amount, options)
        default:
          return amount
      }
    }
  }
}
