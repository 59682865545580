var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"header"}),_c('div',{staticClass:"content"},[_c('div',[_c('h1',[_vm._v("Allgemeine Geschäftsbedingungen (AGB) – Spendengeber")]),_c('h2',[_vm._v("§ 1 Geltungsbereich")]),_vm._m(0),_c('h2',[_vm._v("§ 2 Vertragsabschluss")]),_c('p',[_vm._v("Sofern es sich um ein Dauerschuldverhältnis handelt, kommt der Vertrag mit der Einwilligung durch die Digital.Wolff GmbH in eine gesonderte Nutzungsvereinbarung mit dem Spendengeber zustande.")]),_c('h2',[_vm._v("§ 3 Änderungsvorbehalt")]),_vm._m(1),_c('h2',[_vm._v("§ 4 Beschreibung der Online-Services")]),_vm._m(2),_c('h2',[_vm._v("§ 5 Gebühren")]),_vm._m(3),_c('h2',[_vm._v("§ 6 Unumkehrbarkeit der Transaktion")]),_vm._m(4),_c('h2',[_vm._v("§ 7 Störung & Erreichbarkeit")]),_vm._m(5),_c('h2',[_vm._v("§ 8 Gewährleistung & Mängelhaftung")]),_vm._m(6),_c('h2',[_vm._v("§ 9 Beendigung des Vertragsverhältnisses")]),_vm._m(7),_c('h2',[_vm._v("§ 10 Daten & Datenschutz")]),_vm._m(8),_c('h2',[_vm._v("§ 11 Haftungsbeschränkung bei Daten")]),_c('p',[_vm._v("Datenschutzrechtliche Haftungsansprüche des Spendengebers setzen voraus, dass eine vertragswesentliche Pflicht verletzt worden ist. Derartige Ansprüche sind dann auf die bei Vertragsschluss vorhersehbaren und vertragstypischen Schäden begrenzt. Ansprüche aus Nebenpflichtverletzungen sind ausgeschlossen. Für Schadensersatzansprüche bei Vorsatz und grober Fahrlässigkeit sowie bei Verletzung von Leben, Körper und Gesundheit, die auf einer vorsätzlichen oder fahrlässigen Pflichtverletzung der Digital.Wolff GmbH beruhen, gilt die gesetzliche Verjährungsfrist.")]),_c('h2',[_vm._v("§ 12 Sonstiges")]),_vm._m(9),_c('br'),_c('br'),_c('v-btn',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" zurück ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Diese Geschäftsbedingungen der Digital.Wolff, Plötz & Co GmbH (\"Digital.Wolff GmbH\") gelten ausschließlich gegenüber Einzelpersonen oder juristischen Personen, die mittels elektronischem Zahlungsmittel über eine Plattform der Digital.Wolff GmbH eine freiwillige, anonyme Zuwendung ohne Gegenleistung (\"Spende\") leisten oder leisten wollen. Diese Einzelpersonen oder juristischen Personen werden im Folgenden zusammenfassend \"Spendengeber\" genannt.")]),_c('li',[_vm._v(" Plattformen der Digital.Wolff GmbH, über die Spendengeber Zuwendungen leisten können, sind "),_c('ol',{attrs:{"type":"a","lower-alpha":""}},[_c('li',[_vm._v("alle physischen Produkte mit gekennzeichneten Zahlungsterminals der Digital.Wolff GmbH (Digitaler Klingelbeutel, Digitale Spendensäule, Digitale Spendendose, Digitale Spendenbox und alle weiteren Produkte mit Terminals der Digital.Wolff GmbH) sowie")]),_c('li',[_vm._v("die digitalen Plattformen spende.app und kollekte.app sowie die zugehörigen mobilen Anwendungen. Plattformen, Anwendungen und zugehörige Dienstleistungen werden zusammenfassend als \"Online-Services\" bezeichnet.")])])]),_c('li',[_vm._v("Entgegenstehende oder von unseren Geschäftsbedingungen abweichende Bedingungen des Vertragspartners erkennen wir nur an, wenn wir ausdrücklich schriftlich der Geltung zustimmen.")]),_c('li',[_vm._v("Diese Geschäftsbedingungen gelten auch für alle zukünftigen Geschäfte mit dem Vertragspartner, soweit es sich um Rechtsgeschäfte verwandter Art handelt.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Die Digital.Wolff GmbH behält sich vor, diese Geschäftsbedingungen in Teilen zu ändern oder zu modifizieren, um sie bei Änderungen der Gesetzgebung, der Rechtsprechung oder des Angebots anzupassen, oder um zwingenden behördlichen Entscheidungen nachzukommen.")]),_c('li',[_vm._v("Spendengeber in einem Dauerschuldverhältnis mit der Digital.Wolff GmbH werden vier Wochen im Voraus auf anstehende Änderungen hingewiesen und haben das Recht, binnen dieser Frist Widerspruch zu erheben. Sofern ein Spendengeber von diesem Widerspruchsrecht nicht fristgerecht Gebrauch macht, gelten die aktualisierten Geschäftsbedingungen für ihn automatisch ab Zeitpunkt der Änderung.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Die Online-Services werden als Plattformen angeboten, auf denen Spendengeber Spenden an teilnehmende Spendennehmer (\"Spendennehmer\") richten können. Die Digital.Wolff GmbH ist Betreiber dieser Web-Apps und Vermittler der Zahlungsdienstleistungen. Spenden gelangen vom Spendengeber ohne Umweg auf das Spendenkonto beim Spendennehmer und werden von dort aus dem Spendenzweck zugewiesen.")]),_c('li',[_vm._v("Die Digital.Wolff GmbH ist weder Makler noch Finanzinstitut, Gläubiger oder Wohltätigkeitsorganisation. Die Digital.Wolff GmbH übernimmt keine Garantie, weder ausdrücklich noch implizit, für die Richtigkeit der von den Spendennehmern bereitgestellten Informationen oder die tatsächliche Zuführung zum Verwendungszweck.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Für die Abbuchung einer Spende werden durch die Digital.Wolff GmbH seitens des Spendengebers keine Gebühren erhoben.")]),_c('li',[_vm._v("Bei Download oder Nutzung mobiler Angebote oder Anwendungen der Digital.Wolff GmbH mittels eines mobilen Endgeräts durch den Spendengeber können von dessen Mobilfunkanbieter Standardpreise, Datenraten oder anderen Gebühren erhoben werden.")]),_c('li',[_vm._v("Darüber hinaus besteht die Möglichkeit, dass für über eine Plattform der Digital.Wolff GmbH abgewickelte, vom Spendengeber ausgelöste Abbuchungen durch dessen Finanzinstitut Gebühren berechnet werden. Etwaige Gebühren dieser Natur gehen komplett zulasten des Spendengebers.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Da Spenden vor Erhalt durch den Spendennehmer anonymisiert werden, sind sie einem Spendengeber nachträglich nicht mehr zuzuordnen. Einmal innerhalb des Wirkungsbereichs dieser AGB abgewickelte Spenden werden daher grundsätzlich nicht von der Digital.Wolff GmbH an Spendengeber zurückerstattet.")]),_c('li',[_vm._v("Aus dem selben Grund erhalten Spendengeber weder von der Digital.Wolff GmbH noch vom Spendennehmer eine Transaktionsbestätigung oder Quittung. Eine gültigen Zahlungsnachweis stellt allein das Finanzinstitut des Spendengebers aus.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Die Digital.Wolff GmbH ist bemüht, sämtliche angebotenen Dienste störungsfrei und ununterbrochen bereitzustellen. Dennoch wird Spendengebern keine durchgehende Funktionstüchtigkeit der Plattformen im Geltungsbereich dieser Geschäftsbedingungen garantiert.")]),_c('li',[_vm._v("Stellt der Spendengeber eine Störung oder Einschränkung der Funktionstüchtigkeit einer der Plattformen, insbesondere der Online-Services fest, ist er angehalten, diese bei der Digital.Wolff GmbH anzuzeigen.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Sofern es sich bei einer Vertragsleistung um eine Sache gemäß § 90 BGB handelt, stehen dem Spendengeber die gesetzlichen Gewährleistungsrechte zu.")]),_c('li',[_vm._v("Für alle anderen Vertragsleistungen ist die Haftung beschränkt auf Pflichtverletzungen, die die Digital.Wolff GmbH gegenüber dem Spendengeber zu vertreten hat, Verzug sowie Unmöglichkeit.")]),_c('li',[_vm._v("Für Schadensersatzansprüche bei Vorsatz und grober Fahrlässigkeit sowie bei Verletzung von Leben, Körper und Gesundheit, die auf einer vorsätzlichen oder fahrlässigen Pflichtverletzung der Digital.Wolff GmbH beruhen, gilt die gesetzliche Verjährungsfrist.")]),_c('li',[_vm._v("Eine Nutzung von Plattformen, insbesondere der Online-Services, durch den Spendengeber erfolgt auf dessen eigenes Risiko. Die Leistungen der Plattformen werden ‚wie gesehen' und ‚wie verfügbar' erbracht. Insbesondere ergeben sich für den Spendengeber keine Ansprüche gegenüber der Digital.Wolff GmbH aus Pflichtverletzungen Dritter, deren Waren oder Dienste während der Verwendung der Plattformen genutzt werden.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Sofern es sich um ein Dauerschuldverhältnis handelt, gelten für die Beendigung des Vertragsverhältnisses die Bestimmungen der gesonderten Nutzungsvereinbarung.")]),_c('li',[_vm._v("Ansonsten endet das Vertragsverhältnis mit Ende der Nutzung einer Plattform durch den Spendengeber, oder Beendigung des Nutzungsverhältnisses oder Einstellung der Plattform durch die Digital.Wolff GmbH.")]),_c('li',[_vm._v("Die Digital.Wolff GmbH behält sich vor, einzelne Nutzungsverhältnisse im alleinigen Ermessen auszusetzen oder zu beenden, und zugehörige Daten zu sperren oder zu löschen.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Für sämtliche Informationen im Zusammenhang mit dem Umgang mit den Daten der Spendengeber verweist die Digital.Wolff GmbH auf ihre gesonderte "),_c('a',{attrs:{"href":"https://www.spende.app/datenschutz"}},[_vm._v("Datenschutzerklärung")]),_vm._v(".")]),_c('li',[_vm._v("Werden bei der Nutzung der Plattformen durch den Spendengeber von diesem Kontaktdaten bei der Digital.Wolff GmbH hinterlegt, verpflichtet sich der Spendengeber zur laufenden Aktualisierung dieser Daten oder Löschung veralteter Datensätze.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Dieser Vertrag und die gesamten Rechtsbeziehungen der Parteien unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (CISG).")]),_c('li',[_vm._v("Erfüllungsort und ausschließlicher Gerichtsstand und für alle Streitigkeiten aus diesem Vertrag ist unser Geschäftssitz, sofern sich aus der Auftragsbestätigung nichts anderes ergibt.")]),_c('li',[_vm._v("Alle Vereinbarungen, die zwischen den Parteien zwecks Ausführung eines Vertrages getroffen werden, sind in einem entsprechenden Vertragsbestandteil schriftlich niedergelegt.")])])
}]

export { render, staticRenderFns }