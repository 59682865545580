import { render, staticRenderFns } from "./Imprint.vue?vue&type=template&id=6ecd48eb&scoped=true&"
var script = {}
import style0 from "./Imprint.vue?vue&type=style&index=0&id=6ecd48eb&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ecd48eb",
  null
  
)

export default component.exports