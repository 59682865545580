import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, de },
    current: 'de'
  },
  icons: {
    iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },

  theme: {
    themes: {
      light: {
        primary: '#03867b',
        secondary: '#37AAE6',
        accent: '#554193',
        error: '#ae364b'
      }
    }
  }
})
