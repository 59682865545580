import axios from "axios"
import config from "@/config"
import { SET_STATIC_CONFIGURATION } from "@/jscommon/src/store/configuration/mutation-types"
export default {
  async fetchStaticConfiguration ({ commit, state }) {
    if (state.staticConfiguration) {
      return state.staticConfiguration
    }
    try {
      const response = await axios.get(
        config.backend.rest + "configuration/static"
      )
      commit(SET_STATIC_CONFIGURATION, { staticConfiguration: response.data })
      return response.data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
}
