<template>
  <v-container
    ref="main"
    fill-height
    fluid
    class="main"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="8"
        lg="5"
      >
        <v-card
          max-width="30em"
        >
          <template v-if="!payment">
            <v-progress-circular
              indeterminate
              :size="70"
            />
          </template>
          <template v-else>
            <!-- FIXME show history -->
            <v-card-title><h2>Dauerspende</h2></v-card-title>
            <v-card-text>
              <table v-if="currentOrganization && payment">
                <tr>
                  <td>
                    Organisation:
                  </td>
                  <td>{{ currentOrganization.name }}</td>
                </tr>
                <tr>
                  <td>Spendenzweck:</td>
                  <td>{{ extractDisplayPurpose(payment.primary_payment.payment.collection.plan, 'bart') }}</td>
                </tr>
                <tr>
                  <td>Zahlungsmittel:</td>
                  <td>{{ this.availablePaymentTypes[payment.primary_payment.method_type] }}</td>
                </tr>
                <tr>
                  <td>Spendensumme:</td>
                  <td>{{ formatAmount(payment.amount, { fractionCount: 0 }) }}</td>
                </tr>
              </table>
              <RecurrenceInformation
                v-if="payment.state === 'active'"
                :schedule="payment.schedule"
                :hide-today="false"
                :now="payment.last_execution"
                :end="payment.valid_notafter"
              />
              <template v-else>
                Dauerspende nicht mehr aktiv.
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-dialog
                v-model="showConfirm"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="payment.state === 'active'"
                    text
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Dauerspende beenden
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Dauerspende beenden
                  </v-card-title>

                  <v-card-text class="pt-3">
                    Möchten Sie Ihre Dauerspende beenden? Die nächste Zahlung, die für den
                    {{ payment.next_execution | dateDisplay }} geplant ist, wird dann nicht mehr durchgeführt.
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      text
                      @click="showConfirm = false"
                    >
                      Nichts tun
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="cancelRecurringPayment"
                    >
                      Dauerspende beenden
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import RecurrenceInformation from '@/jscommon/src/components/RecurrenceInformation'
import { dateDisplay } from '@/jscommon/src/lib/date-tools'
import { extractDisplayPurpose } from '@/jscommon/src/lib/regex-tools'
import { mapGetters } from 'vuex'
import { setColor } from '@/lib/colorTools'
import CurrencyMixin from '@/jscommon/src/mixins/currency.mixin'

export default {
  name: 'ManageRecurring',
  mixins: [CurrencyMixin],
  components: { RecurrenceInformation },
  filters: {
    dateDisplay
  },
  data () {
    return {
      payment: null,
      showConfirm: false,
      extractDisplayPurpose,
    }
  },
  computed: {
    ...mapGetters('data', {
      organizationSettingsByUrl: 'organizationSettingsByUrl',
      imageBackgroundUrlByHash: 'imageBackgroundUrlByHash',
      organizationLookupByUrl: 'organizationLookupByUrl'
    }),
    ...mapGetters('configuration', ['enumsByType']),
    availablePaymentTypes () {
      return this.enumsByType('OnlinePaymentType')
    },
    recurringPaymentAuthToken () {
      if (this.$route.name === 'manage-recurring') {
        return this.$route?.params?.recurringPaymentAuthToken
      }
      return null
    },
    organizationUrl () {
      return this?.payment?.primary_payment.payment.collection.organization ?? null
    },
    currentOrganization () {
      return this.organizationLookupByUrl[this.organizationUrl] ?? null
    },
    backgroundImageUrl () {
      // if (!this.organizationUrl) {
      //   return null
      // }
      return this.imageBackgroundUrlByHash[this.organizationSettingsByUrl[this.organizationUrl]?.assets?.WS?.background?.image ?? null] ?? null
    },
    primaryColor () {
      if (!this.organizationUrl) {
        return 'inherit'
      }
      return ((this.organizationSettingsByUrl[this.organizationUrl] ?? {})?.WS ?? {})?.primary_color ?? 'inherit'
    }
  },
  watch: {
    recurringPaymentAuthToken: {
      immediate: true,
      async handler (newVal) {
        if (newVal) {
          this.payment = (await axios.get(`${config.backend.rest}app/recurring_payment/${newVal}/`)).data
        }
      }
    },
    primaryColor: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          setColor(newVal)
          this.$vuetify.theme.themes.light.primary = JSON.parse(JSON.stringify(newVal))
        }
      }
    },
    backgroundImageUrl: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.main.style.setProperty('--spendeapp-background-image', `url(${newVal})`)
          })
        }
      }
    }
  },
  async mounted () {
    await Promise.allSettled([
      this.$store.dispatch('data/fetchOrganizations'),
      this.$store.dispatch('configuration/fetchStaticConfiguration')
    ])
  },
  methods: {
    async cancelRecurringPayment () {
      this.showConfirm = false
      this.payment = (await axios.post(`${config.backend.rest}app/recurring_payment/${this.recurringPaymentAuthToken}/cancel/`)).data
    }
  }
}
</script>

<style scoped lang="stylus">
.main:after
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: white
  background-image: var(--spendeapp-background-image)
  background-position: center center
  background-repeat: no-repeat
  width: 100vw;
  height: 100vh;
  opacity: 0.7;
  z-index: -1;
  background-size: cover

.main
  width: 100vw
  height: 100vh /* Fallback for browsers that do not support Custom Properties */
  overflow: hidden
  justify-content: center
  display: flex
  flex-direction: row

td
  vertical-align: top
  text-align: left

td:first-child
 width: 120px

</style>
