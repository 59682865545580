<template>
  <div>
    <router-link
      v-if="!organization.signUpDummy"
      :to="{ name: 'organization-selected', params: {'organizationId': organization.id}}"
      class="organizationListItem"
      active-class="active"
      :style="thumbnailStyles"
    >
      <img
        v-if="logo"
        :src="logo"
        class="organization-logo"
      >
      <HandSquare
        v-else
        class="organization-logo"
      />
      <div class="organization-label">
        {{ organization.name | shortenParish }}
      </div>
    </router-link>
    <a
      v-else
      href="https://digitalespende.de/spende-app-formular"
      class="organizationListItem join"
    >
      Organisation fehlt?
      <div class="join-button">
        MITMACHEN!
      </div>
    </a>
  </div>
</template>

<script>
import HandSquare from './HandSquare'
import { mapGetters } from 'vuex'

export default {
  name: 'OrganizationTile',
  components: {
    HandSquare
  },
  props: {
    organization: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('data', {
      organizationSettingsByUrl: 'organizationSettingsByUrl',
      imageThumbnailUrlByHash: 'imageThumbnailUrlByHash'
    }),
    thumbnailStyles () {
      const primaryColor = ((this.organizationSettingsByUrl[this.organization.url] ?? {})?.WS ?? {})?.primary_color ?? 'inherit'
      const backgroundImage = this.imageThumbnailUrlByHash[this.organizationSettingsByUrl[this.organization.url]?.assets?.WS?.background?.image ?? null] ?? null
      return `--spendeapp-primary-color: ${primaryColor}; background-image: url('${backgroundImage}')`
    },
    logo () {
      let hash = this.organizationSettingsByUrl[this.organization.url]?.assets?.WS?.logo?.image ?? null
      hash = this.organizationSettingsByUrl[this.organization.url]?.assets?.WS?.main?.image ?? null
      if (!hash) {
        return null
      }
      return this.imageThumbnailUrlByHash[hash] ?? null
    }
  }
}
</script>

<style lang="stylus" scoped>
.organizationListItem:after
  content : "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: white
  width: 100%;
  height: 100%;
  opacity : 0.3;
  z-index: 0;

.organizationListItem
  position: relative
  display: block
  margin-top: 1px
  text-align: left
  vertical-align: middle
  line-height: normal
  width: calc(100% - 1.5em)
  max-width: 20em
  height: calc(100% - 2em)
  background: white
  background-position: center center
  background-repeat: no-repeat
  background-size: cover
  z-index: 0

  @media only screen and (max-width: 65em),
  only screen and (max-height: 39em)
    margin-left: auto
    margin-right: auto

  .organization-logo
    float: right
    margin: 0.3em
    max-width: 3em
    max-height: 3em

  .organization-label
    background: rgba(255,255,255,0.8)
    font-size: 14px
    color: black
    font-weight: bold
    position: absolute
    bottom: 0
    padding: 0.2em
    width: calc(100%)
    margin-bottom: 1em
    z-index: 2

    @media only screen and (max-width: 65em),
    only screen and (max-height: 39em)
      margin-bottom: 1.8em

.organizationListItem.join
  text-align: center
  display: flex;
  flex-direction: column
  justify-content: center;
  color: #808080
  font-weight: 550
  font-size: normal
  text-decoration: none
  padding: 0em
  border: 0
  height: calc(100% - 2em)

  @media only screen and (max-width: 65em),
  only screen and (max-height: 39em)
    calc(100% - 1.5em)

  .join-button
    background: var(--spendeapp-primary-color)
    color: white
    align-self: center;
    padding-top: 0.7em
    padding-bottom: 0.7em
    padding-left: 1.5em
    padding-right: 1.5em
    margin-top: 2em
    font-size: x-small
    border-radius: 4px
    box-shadow: 0px 3px 8px rgba(0,0,0,0.5)
    z-index: 8

    @media only screen and (max-width: 65em),
    only screen and (max-height: 39em)
      font-size: smaller
      margin-top: 1.5em

.organizationListItem.join:hover
  .join-button
    text-decoration: underline

.organizationListItem.active
  border: 2px solid var(--spendeapp-primary-color)
</style>
