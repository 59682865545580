import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'
import ToS from './views/ToS.vue'
import DataPrivacy from './views/DataPrivacy.vue'
import Collection from './views/Collection.vue'
import Impressum from './views/Impressum.vue'

import store from './store'
import ManageRecurring from '@/views/ManageRecurring'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: "/",
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    }, {
      path: '/o/:organizationId',
      name: 'organization-selected',
      component: Collection
    }, {
      path: '/o/:organizationId/done/:onlinePaymentId?',
      name: 'done',
      component: Collection
    }, {
      path: '/datenschutz',
      name: 'data-privacy',
      component: DataPrivacy
    }, {
      path: '/agb',
      name: 'agbs',
      component: ToS
    }, {
      path: '/impressum',
      name: 'impressum',
      component: Impressum
    }, {
      path: '/recurring/:recurringPaymentAuthToken',
      name: 'manage-recurring',
      component: ManageRecurring
    }
  ]
})
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next({ name: 'home' })
  } else if (to.name === 'home' && store.state.selectedOrganizationId) { // FIXME Restore saved state
    next({
      name: 'organization-selected',
      params: { organizationId: store.state.selectedOrganizationId }
    })
  } else next()
})

export function getDonePath ({ organizationId, onlinePaymentId }) {
  return router.resolve({ name: 'done', params: { organizationId, onlinePaymentId } })
}

export default router
