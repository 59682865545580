import { SET_ORGANIZATION_AND_COLLECTION } from '@/store/mutation-types'

export async function setOrganizationId ({ commit, state, rootGetters, dispatch }, value) {
  let collectionIndex = state.collectionIndex
  value = value ?? null
  if (state.organizationId !== value) {
    collectionIndex = 0
    if (!rootGetters['data/organizationLookupById'][value]) {
      await dispatch('data/fetchOrganizations', {}, { root: true })
    }
    await dispatch('configuration/fetchStaticConfiguration', {}, { root: true })
    const organizationUrl = rootGetters['data/organizationLookupById'][value]?.url
    if (!organizationUrl) {
      value = null
    } else {
      await dispatch('data/fetchCollectionInformationForUrl', { organizationUrl }, { root: true })
    }
  }
  commit(SET_ORGANIZATION_AND_COLLECTION, { organizationId: value, collectionIndex })
}

export function setCollectionIndex ({ commit, getters, state }, value) {
  const collectionIndex = Math.min(Math.max(0, value), getters.collections.length - 1)
  commit(SET_ORGANIZATION_AND_COLLECTION, { organizationId: state.organizationId, collectionIndex })
}

export function increaseCollectionIndex ({ dispatch, state, getters }) {
  let nextIndex = state.collectionIndex + 1
  if (nextIndex > getters.collections.length - 1) {
    nextIndex = 0
  }
  dispatch('setCollectionIndex', nextIndex)
}

export function decreaseCollectionIndex ({ dispatch, state, getters }) {
  let nextIndex = state.collectionIndex - 1
  if (nextIndex < 0) {
    nextIndex = getters.collections.length - 1
  }
  dispatch('setCollectionIndex', nextIndex)
}
