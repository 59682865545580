<template>
  <div>
    <div
      v-if="state && ![STATE_INIT, STATE_DONE].includes(state)"
      id="payment-container"
      ref="payment-container"
      style="color: rgba(0, 0, 0, 0.54)"
    >
      <h4 v-if="selectedMethod.method === 'sepadd'">SEPA-Lastschrift</h4>
      <h4 v-else-if="selectedMethod.method === 'wallee'">
        <!-- Kreditkartenzahlung -->
        VISA / Mastercard
      </h4>
      <v-expand-transition>
        <div v-if="!formReady" style="width: 3em" class="ma-auto mb-4">
          <v-progress-circular
            color="primary"
            :size="70"
            :width="5"
            indeterminate
          />
        </div>
      </v-expand-transition>
    </div>
    <template v-if="state === STATE_INIT">
      <slot name="initiatingpre" />
      <slot name="initiatingpost" />
    </template>
    <slot name="actions" />
    <slot name="footer" :footerInfo="additionalFooterInformation" />
  </div>
</template>
<script>
import { mapState } from "vuex"
import config from "@/config"
import {
  UPDATE_INTEGRATION_DATA,
  SET_MEMO_TEXT,
} from "../store/payment/mutation-types"
import axios from "axios"
import { getDonePath } from "@/router"
import {
  STATE_DONE,
  STATE_HANDLE,
  STATE_INIT,
  STATE_CHECK,
} from "../store/payment/state-types"
const METHOD_ID = {
  creditcard: 0,
  twint: 1,
}
export default {
  name: "PaymentFragmentWallee",
  props: {
    state: {
      type: String,
      required: false,
      default: null,
    },
    appKind: {
      validator: (x) => ["spende.app"].includes(x),
      default: "spende.app",
    },
    selectedMethod: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      formRendered: false,
      formReady: false,
      STATE_INIT,
      STATE_DONE,
      METHOD_ID
    }
  },
  computed: {
    ...mapState("payment", ["integrationData", "memoText"]),
    ...mapState("payment", {
      paymentInput: "input",
    }),
    brand () {
      if (this.selectedMethod?.method === "wallee") {
        const enabledBrands = ["VISA", "MASTER"].filter((x) =>
          config.payment.enabled.includes(x)
        )
        return enabledBrands.join(" ")
      }
      return null
    },
    additionalFooterInformation () {
      return 'Auf Ihrem Kontoauszug erscheint das Wort "Spende" und der Name der Organisation als Verwendungszweck.'
    },
  },
  watch: {
    state: {
      immediate: true,
      async handler (newValue, oldValue) {
        if (newValue === STATE_HANDLE) {
          await this.startPaymentOnBackend()
          await this.$nextTick()
        } else if (newValue === STATE_CHECK) {
          await this.completePaymentOnBackend()
        } else if (newValue === STATE_DONE) {
          if (this.formRendered) {
            this._intDestroyPaymentForm()
          }
        }
      },
    },
  },
  mounted () {
    this.$el.style.setProperty(
      "--gebendigital-jscommon-integration-oppwa-primary-color",
      this.$vuetify.theme.currentTheme.primary
    )
  },
  methods: {
    async startPaymentOnBackend () {
      const recurring = this.paymentInput.doRecurring
        ? {
            schedule: this.integrationData?.additionalData?.schedule,
            email: this.integrationData?.additionalData?.email,
          }
        : null
      const response = await axios.post(
        `${config.backend.rest}app/online_payment/start_payment/?app=${this.appKind}`,
        {
          collection_id: this.paymentInput.collection.id,
          collection: this.paymentInput.collection.id,
          amount: this.paymentInput.amount,
          ...this.selectedMethod,
          organization: this.paymentInput.parishId,
          person_data: this.$store.state.payment.personData,
          recurring,
        }
      )
      const data = response.data
      const path = getDonePath({
        organizationId: this.paymentInput.parishId,
        onlinePaymentId: data?.online_payment?.id,
        integration: "wallee"
      }).href
      const successUrl = window.location.origin + path

      // Render Wallee payment form from javascript file URL
      const scriptElement = document.createElement('script')
      scriptElement.src = data?.javascript_url

      scriptElement.onload = () => {
        const paymentMethodConfigurationId = data?.possible_payment_methods[this.METHOD_ID[this.selectedMethod.method]]?.id
        window.LightboxCheckoutHandler.startPayment(paymentMethodConfigurationId, function () {
          this.$emit("goto", "done", {
            successful: false,
            message: "Technischer Fehler in der Datenübermittlung",
          })
        })
      }
      document.head.appendChild(scriptElement)

      await Promise.allSettled([
        this.$store.commit("payment/" + UPDATE_INTEGRATION_DATA, {
          successUrl,
          onlinePaymentId: data?.online_payment?.id,
        }),
        this.$store.commit("payment/" + SET_MEMO_TEXT, {
          memoText: data?.online_payment?.memo_text || null,
        }),
      ])
    },

    async completePaymentOnBackend () {
      try {
        const response = await axios.post(
          `${config.backend.rest}app/online_payment/update_payment/?app=${this.appKind}`,
          {
            online_payment: this.$store.state.route.params.onlinePaymentId,
          }
        )
        const data = response.data
        await Promise.allSettled([
          this.$store.commit("payment/" + SET_MEMO_TEXT, {
            memoText: data?.online_payment?.memo_text || null,
          }),
        ])
        this.$emit("goto", "done", {
          successful: ["completed"].includes(
            data.payment.state
          ),
          data,
        })
      } catch (error) {
        this.$emit("goto", "done", {
          successful: false,
          message: `Technischer Fehler in der Datenübermittlung: ${error}`,
        })
      }
    },
  },
}
</script>

<style lang="stylus" scoped></style>
