<template>
  <div class="hand-icon">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 56.7 56.7"
      style="enable-background:new 0 0 56.7 56.7;"
      xml:space="preserve"
    >
      <g
        id="Ebene_1"
        class="st0"
      >
        <g
          id="Ebene_2_1_"
          class="st1"
        >
          <g id="Ebene_1-2">
            <g>
              <rect
                x="-76.7"
                class="st2"
                width="56.7"
                height="56.7"
              />
              <g>
                <path
                  class="st3"
                  d="M-62.8,26.4c2-2,4.7-3.2,7.5-3.4c4.4,0,12.8,5.8,12.8,5.8c2.7,1.8,0.5,3.8-2.2,2.6l-8.5-3.4l-0.9,1.6
            l6.8,2.9c1.8,0.7,3.8,1.5,5.6,1c1.6-0.3,2.7-1.8,2.6-3.4c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.4-0.4-0.8-0.7-1.1l0.5-0.4
            c0.6-0.5,6.6-3.2,9.1-2.7c3.2,0.7,5,4,4,4.8s-18.4,9.5-22.7,9.8c-4.3,0.3-13.8-5.5-13.8-5.5V26"
                />
                <rect
                  x="-69.4"
                  y="25.3"
                  class="st3"
                  width="4.8"
                  height="11.7"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Ebene_2">
        <circle
          class="st4"
          cx="28.5"
          cy="28.5"
          r="26.9"
        />
        <g>
          <polygon
            class="st2"
            points="25.6,39.3 25.6,29.3 29.8,29.3 29.8,39.3    "
          />
          <path
            class="st5"
            d="M20.2,20.4c0-5.5,4.1-8.9,9.3-8.9c5.5,0,9.9,4.4,9.9,9.9s-4.4,9.9-9.9,9.9c-0.2,0-0.3,0-0.5,0"
          />
          <rect
            x="25.6"
            y="42.6"
            class="st2"
            width="4.2"
            height="4.9"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'QuestionMark'
}
</script>
<style lang="stylus" scoped>
.hand-icon
  display: inline-block
  margin-right: env(safe-area-inset-right)

.st0{display:none;}
  .st1{display:inline;}
  .st2{fill:var(--spendeapp-primary-color);}
  .st3{fill:rgba(0,0,0,0);}
  .st4{fill:rgba(0,0,0,0);stroke:var(--spendeapp-primary-color);stroke-width:2;stroke-miterlimit:10;}
  .st5{fill:none;stroke:var(--spendeapp-primary-color);stroke-width:4;stroke-miterlimit:10;}
</style>
