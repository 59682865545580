<template>
  <div
    id="imprint"
    class="main"
  >
    <div class="content">
      <Imprint />
      <br>
      <br>
      <a @click="$router.go(-1)">zurück</a>
    </div>
  </div>
</template>
<script>
import Imprint from '@/components/Imprint'
export default {
  name: 'Imprint',
  components: {
    Imprint
  }
}
</script>
<style scoped lang="stylus">
@import '../styles/main'
.content
  overflow: auto
  padding-left: 3em
  padding-right: 3em
  padding-bottom: 3em
  top: 3em
  bottom: 0
  position: fixed
a
  cursor: pointer
</style>
