import { render, staticRenderFns } from "./ToS.vue?vue&type=template&id=4c792f1c&scoped=true&"
var script = {}
import style0 from "./ToS.vue?vue&type=style&index=0&id=4c792f1c&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c792f1c",
  null
  
)

export default component.exports